import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'

import { useIntl } from '../../intl/intl'
import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import LayoutGame from '../../components/LayoutGame'
import GamePageTemplate from '../../templates/GamePage'
import router from '../../router'
import routes from '../../router/routes'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'


const GameOverload = (props) => {
  const { pageContext, location } = props
  const t = useIntl()
  const { seoFallback } = useConfiguration()

  const layoutProps = useMemo(() => ({
    videoBackgroundSources: [
      {
        src: t('background_video_path'),
        type: 'video/mp4',
      },
    ],
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title, t])

  const gameOverloadProps = {
    text: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value: t('overload_message'),
          marks: [],
          data: {},
        },
      ],
    },
    startNavProps: {
      buttons: [
        {
          text: t('try_again'),
          variant: 'bigtransparent',
          gatsbyLink: {
            route: router(pageContext?.isVip ? routes.vipGame.path : routes.game.path),
          },
        },
      ],
    },
  }

  return (
    <LayoutGame {...layoutProps}>
      <GamePageTemplate {...gameOverloadProps} />
    </LayoutGame>
  )
}

GameOverload.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

GameOverload.defaultProps = {
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(GameOverload)))
